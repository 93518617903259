"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "intro-section" };
var vue_router_1 = require("vue-router");
var vue_3 = require("vue");
var aos_1 = require("aos");
exports.default = (0, vue_1.defineComponent)({
    __name: 'IntroSection',
    setup: function (__props) {
        var router = (0, vue_router_1.useRouter)();
        var openResume = function () {
            window.open('/Joseph_Holm_Resume.pdf', '_blank');
        };
        var goToBlog = function () {
            router.push('/blog');
        };
        (0, vue_3.onMounted)(function () {
            aos_1.default.init();
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", { class: "content-wrapper" }, [
                    (0, vue_2.createElementVNode)("div", { class: "text-content" }, [
                        _cache[1] || (_cache[1] = (0, vue_2.createStaticVNode)("<div class=\"header-group\" data-v-a01518b2><h1 class=\"name\" data-v-a01518b2>Joseph Holm</h1><h2 class=\"title\" data-v-a01518b2>Software Engineer</h2></div><p class=\"description\" data-v-a01518b2> I&#39;m a Computer Science student at ASU passionate about creating impactful software solutions. I&#39;m also always down for a good hackathon every now and then. </p><p class=\"description\" data-v-a01518b2> In my spare time when I&#39;m not coding, you&#39;ll find me reading books, watching movies and TV shows, or playing badminton and volleyball. </p>", 3)),
                        (0, vue_2.createElementVNode)("div", { class: "action-buttons" }, [
                            (0, vue_2.createElementVNode)("button", {
                                onClick: openResume,
                                class: "resume-button"
                            }, _cache[0] || (_cache[0] = [
                                (0, vue_2.createTextVNode)(" View Resume "),
                                (0, vue_2.createElementVNode)("span", { class: "button-icon" }, "→", -1)
                            ]))
                        ])
                    ]),
                    _cache[2] || (_cache[2] = (0, vue_2.createElementVNode)("div", { class: "avatar-container" }, [
                        (0, vue_2.createElementVNode)("div", { class: "avatar-frame" }, [
                            (0, vue_2.createElementVNode)("img", {
                                src: "/assets/me2.jpg",
                                alt: "Joseph Holm",
                                class: "avatar-image"
                            }),
                            (0, vue_2.createElementVNode)("div", { class: "avatar-overlay" })
                        ])
                    ], -1))
                ])
            ]));
        };
    }
});
