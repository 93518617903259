"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "footer" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'FooterSection',
    setup: function (__props) {
        var openEmail = function () {
            window.location.href = 'mailto:joseph.holm27@gmail.com';
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("footer", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", { class: "content" }, [
                    _cache[0] || (_cache[0] = (0, vue_2.createElementVNode)("h2", { class: "title" }, "Let's Work Together", -1)),
                    _cache[1] || (_cache[1] = (0, vue_2.createElementVNode)("p", { class: "description" }, " Whether you have a project in mind or just want to connect, I'm always open to new opportunities and conversations. ", -1)),
                    (0, vue_2.createElementVNode)("button", {
                        onClick: openEmail,
                        class: "contact-button"
                    }, " Get in Touch "),
                    _cache[2] || (_cache[2] = (0, vue_2.createElementVNode)("div", { class: "bottom" }, [
                        (0, vue_2.createElementVNode)("div", { class: "credit" }, [
                            (0, vue_2.createElementVNode)("span", null, "2025 Joseph Holm"),
                            (0, vue_2.createElementVNode)("span", null, "Built with Vue.js")
                        ]),
                        (0, vue_2.createElementVNode)("a", {
                            href: "https://github.com/Hololm/portfolio",
                            target: "_blank",
                            class: "source"
                        }, [
                            (0, vue_2.createElementVNode)("img", {
                                src: "/assets/github.svg",
                                alt: "GitHub"
                            }),
                            (0, vue_2.createTextVNode)(" Source Code ")
                        ])
                    ], -1))
                ])
            ]));
        };
    }
});
