"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'NavBar',
    setup: function (__props) {
        var sendContact = function () {
            window.location.href = 'mailto:joseph.holm27@gmail.com';
        };
        var openResume = function () {
            window.open('/Joseph_Holm_Resume.pdf', '_blank');
        };
        var visible = (0, vue_3.ref)(true);
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("nav", {
                class: (0, vue_2.normalizeClass)(["navbar", { hidden: !visible.value }])
            }, [
                (0, vue_2.createElementVNode)("div", { class: "nav-group left" }, [
                    (0, vue_2.createElementVNode)("button", {
                        onClick: sendContact,
                        class: "nav-button",
                        title: "Contact"
                    }, _cache[0] || (_cache[0] = [
                        (0, vue_2.createElementVNode)("i", { class: "icon email-icon" }, null, -1)
                    ])),
                    (0, vue_2.createElementVNode)("button", {
                        onClick: openResume,
                        class: "nav-button",
                        title: "Resume"
                    }, _cache[1] || (_cache[1] = [
                        (0, vue_2.createElementVNode)("i", { class: "icon resume-icon" }, null, -1)
                    ]))
                ]),
                _cache[2] || (_cache[2] = (0, vue_2.createStaticVNode)("<div class=\"nav-group right\" data-v-17a4fc58><a href=\"https://github.com/Hololm\" target=\"_blank\" class=\"nav-button\" data-v-17a4fc58><i class=\"icon github-icon\" data-v-17a4fc58></i></a><a href=\"https://linkedin.com/in/joseph-holm\" target=\"_blank\" class=\"nav-button\" data-v-17a4fc58><i class=\"icon linkedin-icon\" data-v-17a4fc58></i></a></div>", 1))
            ], 2));
        };
    }
});
