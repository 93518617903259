"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_router_1 = require("vue-router");
var IntroSection_vue_1 = require("@/components/IntroSection.vue");
var ProjectSection_vue_1 = require("@/components/ProjectSection.vue");
var WorkSection_vue_1 = require("@/components/WorkSection.vue");
var TechSection_vue_1 = require("@/components/TechSection.vue");
var FooterSection_vue_1 = require("@/components/FooterSection.vue");
var NavBar_vue_1 = require("@/components/NavBar.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'HomeView',
    setup: function (__props) {
        var router = (0, vue_router_1.useRouter)();
        var goToAbout = function () {
            router.push('/about');
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, [
                (0, vue_2.createVNode)(NavBar_vue_1.default),
                (0, vue_2.createVNode)(IntroSection_vue_1.default),
                (0, vue_2.createVNode)(WorkSection_vue_1.default),
                (0, vue_2.createVNode)(ProjectSection_vue_1.default),
                (0, vue_2.createVNode)(TechSection_vue_1.default),
                (0, vue_2.createVNode)(FooterSection_vue_1.default)
            ], 64));
        };
    }
});
