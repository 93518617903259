"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "job-card" };
var _hoisted_2 = { class: "timeline" };
var _hoisted_3 = { class: "date" };
var _hoisted_4 = ["src", "alt"];
var _hoisted_5 = { class: "content" };
var _hoisted_6 = { class: "company" };
var _hoisted_7 = { class: "role-location" };
var _hoisted_8 = { class: "role" };
var _hoisted_9 = { class: "location" };
var _hoisted_10 = { class: "details" };
var _hoisted_11 = { class: "bullet-text" };
var _hoisted_12 = {
    key: 0,
    class: "tech-stack"
};
exports.default = (0, vue_1.defineComponent)({
    __name: 'JobCard',
    props: {
        logo: {},
        company: {},
        role: {},
        startDate: {},
        endDate: {},
        location: {},
        bulletPoints: {},
        websiteUrl: {},
        technologies: {}
    },
    setup: function (__props) {
        var openWork = function (website, event) {
            event.stopPropagation();
            window.open(website, '_blank');
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("div", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.startDate) + " - " + (0, vue_2.toDisplayString)(_ctx.endDate), 1),
                    (0, vue_2.createElementVNode)("div", {
                        class: "logo-container",
                        onClick: _cache[0] || (_cache[0] = (0, vue_2.withModifiers)(function ($event) { return (openWork(_ctx.websiteUrl, $event)); }, ["stop"]))
                    }, [
                        (0, vue_2.createElementVNode)("img", {
                            src: _ctx.logo,
                            alt: _ctx.company,
                            class: "company-logo"
                        }, null, 8, _hoisted_4)
                    ])
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_5, [
                    (0, vue_2.createElementVNode)("h3", _hoisted_6, (0, vue_2.toDisplayString)(_ctx.company), 1),
                    (0, vue_2.createElementVNode)("div", _hoisted_7, [
                        (0, vue_2.createElementVNode)("span", _hoisted_8, (0, vue_2.toDisplayString)(_ctx.role), 1),
                        (0, vue_2.createElementVNode)("span", _hoisted_9, (0, vue_2.toDisplayString)(_ctx.location), 1)
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_10, [
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.bulletPoints, function (point, index) {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                key: index,
                                class: "bullet"
                            }, [
                                _cache[1] || (_cache[1] = (0, vue_2.createElementVNode)("div", { class: "bullet-icon" }, null, -1)),
                                (0, vue_2.createElementVNode)("p", _hoisted_11, (0, vue_2.toDisplayString)(point), 1)
                            ]));
                        }), 128))
                    ]),
                    (_ctx.technologies)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_12, [
                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.technologies, function (tech) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                                    key: tech,
                                    class: "tech-tag"
                                }, (0, vue_2.toDisplayString)(tech), 1));
                            }), 128))
                        ]))
                        : (0, vue_2.createCommentVNode)("", true)
                ])
            ]));
        };
    }
});
