"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "work-section" };
var _hoisted_2 = { class: "jobs-container" };
var JobCard_vue_1 = require("@/components/JobCard.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'WorkSection',
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                _cache[0] || (_cache[0] = (0, vue_2.createElementVNode)("h2", { class: "work-title" }, "Professional Experience", -1)),
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createVNode)(JobCard_vue_1.default, {
                        logo: "/assets/docusign.svg",
                        company: "Docusign",
                        role: "Incoming Software Engineering Intern",
                        startDate: "Jun. 2025",
                        endDate: "Aug. 2025",
                        location: "San Francisco Bay Area, CA",
                        technologies: ['Node.js', 'Python', 'Salesforce', 'AWS'],
                        bulletPoints: [
                            'Incoming summer 2025 ☀️',
                            'Platform team 🚀'
                        ],
                        websiteUrl: "https://www.docusign.com/"
                    }),
                    (0, vue_2.createVNode)(JobCard_vue_1.default, {
                        logo: "/assets/epics.jpg",
                        company: "epics@ASU",
                        role: "Backend Software Engineer",
                        startDate: "Jan. 2025",
                        endDate: "Present",
                        location: "Tempe, AZ",
                        technologies: ['React Native', 'FastAPI', 'TypeScript', 'Python', 'PostgreSQL', 'AWS'],
                        bulletPoints: [
                            'Documentation lead for the team, contributing to backend infrastructure of campus navigation app',
                            'Optimized FastAPI endpoints to deliver 25+ campus amenities data in <80ms via Axios fetch calls',
                            'Enforced code docs standards via GitHub PR reviews, accelerating team efficiency and organization'
                        ],
                        websiteUrl: "https://epics.engineering.asu.edu/"
                    }),
                    (0, vue_2.createVNode)(JobCard_vue_1.default, {
                        logo: "/assets/soda.svg",
                        company: "The Software Developer's Association",
                        role: "Software Development Engineering Intern",
                        startDate: "Oct. 2024",
                        endDate: "Present",
                        location: "Tempe, AZ",
                        technologies: ['Vue.js', 'JavaScript', 'Node.js', 'Express.js', 'MongoDB'],
                        bulletPoints: [
                            'Architected Vue.js chat interface with WebSocket integration for real-time cross-platform message synchronization across Instagram and WhatsApp',
                            'DBuilt and deployed REST API endpoints with Express and Node for secure message processing and retrieval',
                            'Recognized as top-performing intern, presenting project to 100+ industry professionals at company showcase'
                        ],
                        websiteUrl: "https://thesoda.io/mentorship"
                    }),
                    (0, vue_2.createVNode)(JobCard_vue_1.default, {
                        logo: "/assets/banner.svg",
                        company: "Banner Health",
                        role: "Software Engineer Intern",
                        startDate: "Mar. 2023",
                        endDate: "May 2023",
                        location: "Surprise, AZ",
                        bulletPoints: [
                            'Minimized manual data entry errors by 25% by automating healthcare workflows using BluePrism',
                            'Cut development time from 3 to 1.5 weeks by creating reusable BluePrism templates and schemas',
                            'Built comprehensive RPA documentation enabling seamless operations team handoffs'
                        ],
                        technologies: ['BluePrism', 'Python', 'RPA'],
                        websiteUrl: "https://www.bannerhealth.com/"
                    })
                ])
            ]));
        };
    }
});
