"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "project-card" };
var _hoisted_2 = { class: "image-container" };
var _hoisted_3 = ["src", "alt"];
var _hoisted_4 = { class: "content" };
var _hoisted_5 = { class: "title" };
var _hoisted_6 = { class: "description" };
var _hoisted_7 = { class: "tech-stack" };
var _hoisted_8 = { class: "links" };
var _hoisted_9 = ["href"];
var _hoisted_10 = ["href"];
var _hoisted_11 = ["href"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'ProjectComponent',
    props: {
        title: {},
        description: {},
        technologies: {},
        imageSrc: {},
        imageAlt: {},
        githubLink: {},
        devpostLink: {},
        youtubeLink: {}
    },
    setup: function (__props) {
        var props = __props;
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("img", {
                        src: _ctx.imageSrc,
                        alt: _ctx.imageAlt || _ctx.title,
                        class: "project-image"
                    }, null, 8, _hoisted_3)
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_4, [
                    (0, vue_2.createElementVNode)("h3", _hoisted_5, (0, vue_2.toDisplayString)(_ctx.title), 1),
                    (0, vue_2.createElementVNode)("div", _hoisted_6, [
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.description, function (paragraph, index) {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("p", { key: index }, (0, vue_2.toDisplayString)(paragraph), 1));
                        }), 128))
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_7, [
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.technologies, function (tech) {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                                key: tech.name,
                                class: "tech-tag"
                            }, (0, vue_2.toDisplayString)(tech.name), 1));
                        }), 128))
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_8, [
                        (_ctx.githubLink)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("a", {
                                key: 0,
                                href: _ctx.githubLink,
                                target: "_blank",
                                class: "link"
                            }, _cache[0] || (_cache[0] = [
                                (0, vue_2.createElementVNode)("img", {
                                    src: "/assets/github.svg",
                                    alt: "GitHub",
                                    class: "github-icon"
                                }, null, -1)
                            ]), 8, _hoisted_9))
                            : (0, vue_2.createCommentVNode)("", true),
                        (_ctx.devpostLink)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("a", {
                                key: 1,
                                href: _ctx.devpostLink,
                                target: "_blank",
                                class: "link"
                            }, _cache[1] || (_cache[1] = [
                                (0, vue_2.createElementVNode)("img", {
                                    src: "/assets/devpost.svg",
                                    alt: "DevPost",
                                    class: "devpost-icon"
                                }, null, -1)
                            ]), 8, _hoisted_10))
                            : (0, vue_2.createCommentVNode)("", true),
                        (_ctx.youtubeLink)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("a", {
                                key: 2,
                                href: _ctx.youtubeLink,
                                target: "_blank",
                                class: "link"
                            }, _cache[2] || (_cache[2] = [
                                (0, vue_2.createElementVNode)("img", {
                                    src: "/assets/youtube.svg",
                                    alt: "YouTube",
                                    class: "youtube-icon"
                                }, null, -1)
                            ]), 8, _hoisted_11))
                            : (0, vue_2.createCommentVNode)("", true)
                    ])
                ])
            ]));
        };
    }
});
